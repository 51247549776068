import {main_header} from './main/header';
import {main_offer} from "@/translation/main/offer";
import {main_projects} from "@/translation/main/projects";
import {main_form_order} from "@/translation/main/form_order";
import {main_form_work} from "@/translation/main/form_work";
import {main_services} from "@/translation/main/services";
import {main_contacts} from "@/translation/main/contacts";
import {main_work} from "@/translation/main/work";
import {footer} from "@/translation/footer";
import {ppolicy} from "@/translation/ppolicy/ppolicy";

export default {
    en: {
        message: {
            company_name: 'tess.web',
            company_url: "https://tessweb.tech",
            company_mail: 'tess.web2b@gmail.com',

            ...footer.en,

            ...main_header.en,
            ...main_offer.en,
            ...main_services.en,
            ...main_projects.en,
            ...main_form_order.en,
            ...main_form_work.en,
            ...main_work.en,
            ...main_contacts.en,

            ...ppolicy.en,
        }
    },
    ru: {
        message: {
            company_name: 'tess.web',
            company_url: "https://tessweb.tech",
            company_mail: 'tess.web2b@gmail.com',

            ...footer.ru,

            ...main_header.ru,
            ...main_offer.ru,
            ...main_services.ru,
            ...main_projects.ru,
            ...main_form_order.ru,
            ...main_form_work.ru,
            ...main_work.ru,
            ...main_contacts.ru,

            ...ppolicy.ru,
        }
    }
};