export const main_projects = {
    en: {
        teledate: 'Teledate app identity and motion design',
        chatshub: 'Development and support of a neural network project',
        stormtool: 'Creating a backend architecture, optimizing the code and implementing edits into the project',
        isrex: 'Automation of processing applications in Telegram for the financial company ISREX',
    },
    ru: {
        teledate: 'Создание айдентики и motion design приложения Teledate',
        chatshub: 'Разработка и поддержка нейросетевого проекта',
        stormtool: 'Создание архитектуры серверной части, оптимизация кода и внедрение изменений в проект',
        isrex: 'Автоматизация обработки заявок в Telegram для финансовой компании ISREX',
    }
}