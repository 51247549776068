import {createPinia} from "pinia";
import {defineStore} from "pinia";

export default createPinia()

export const Store = defineStore('store', {
    state: () => {
        return {
            // api_url: 'https://ideally-apparent-newt.ngrok-free.app/api',
            // api_url: 'http://localhost/api',
            api_url: 'https://tessweb.tech/api',
            locale: 'en',
        }
    },
    getters: {
        getAPI: state => {
            return state.api_url
        },
        getLocale: state => {
            return state.locale
        }
    },
    actions: {
        setLocale(newLocale) {
            this.locale = newLocale;
        }
    }
})