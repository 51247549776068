export const ppolicy = {
    en: {
        ppolicy_main_header: 'Policy regarding the processing of personal data',

        ppolicy_header_point_1: '1. General provisions',
        ppolicy_header_point_1_description_part_1: 'This policy for processing political compilation data in accordance with the requirements of the Federal Law of July 27, 2006. No. 152-FZ "Personal Data" (hereinafter - Health Data Act) and definition of processing digital data and currency security measures proprietary data',
        ppolicy_header_point_1_description_part_2: '(hereinafter - Operator)',
        ppolicy_header_point_1_description_p_1: '1.1. The operator formulates his respected goal and the conditions for its implementation the activity of observing the rights and freedoms of man and citizen when processing his medical data, including number of protection of rights to privacy, personal and family secrets.',
        ppolicy_header_point_1_description_p_2: '1.2. This Operator`s policy regarding the processing of personal data (hereinafter referred to as the Policy) applies to all information that the Operator may receive about website visitors',

        ppolicy_header_point_2: '2. Basic concepts used in politics',
        ppolicy_header_point_2_description_p_1: '2.1. Automated processing of digital data - processing of digital data using computer technology.',
        ppolicy_header_point_2_description_p_2: '2.2. Blocking of digital data is a temporary continuation of the processing of digital data (except for cases where processing is necessary to clarify digital data).',
        ppolicy_header_point_2_description_p_3: '2.3. Website - a collection of graphic and information materials, as well as computer programs and databases that ensure their availability on the Internet at a network address',
        ppolicy_header_point_2_description_p_4: '2.4. Information system of digital data - a set of digital data contained in databases and ensuring their processing by information technologies and technical means.',
        ppolicy_header_point_2_description_p_5: '2.5. Depersonalization of digital data is an action as a result of which it is impossible to determine, without the use of additional information, the property belonging to a specific User or other subject of digital data.',
        ppolicy_header_point_2_description_p_6: '2.6. Processing of digital data - any action (operation) or set of actions (operations) performed using automation tools or without the use of such means with personal data, including collection, recording, systematization, accumulation, storage, clarification (updating, changing), extraction, use, transfer (distribution, provision, access), depersonalization, blocking, deletion, destruction of medical data.',
        ppolicy_header_point_2_description_p_7: '2.7. Operator - a state body, municipal body, legal or public entity, alone or jointly with other persons, organizing and/or carrying out the processing of financial data, as well as determining the purposes of processing financial data, the composition of financial data, contactless processing, actions (operations) performed with personal data.',
        ppolicy_header_point_2_description_p_8: '2.8. Personal data - any information directly or limitedly related to the weakening or customization of the Website User',
        ppolicy_header_point_2_description_p_9: '2.9. Personal data authorized by medical data subjects for distribution - personal data, access to an unlimited number of persons to whom medical data subjects are provided by agreeing on the processing of medical data authorized by medical data subjects for distribution in the manner prescribed by the Financial Data Law (hereinafter referred to as personal data ) data permitted for distribution).',
        ppolicy_header_point_2_description_p_10: '2.10. User - any visitor to the website',
        ppolicy_header_point_2_description_p_11: '2.11. Providing digital data - actions aimed at disclosing medical data, softening a person or softening a circle of people.',
        ppolicy_header_point_2_description_p_12: '2.12. Distribution of digital data - any actions aimed at disclosing digital data that restricts a circle of persons (transfer of monetary data) or to familiarize with personal data of an unlimited circle of persons, including the publication of digital data in the media, placement in information and telecommunication networks, or provision access. to personal datain another way.',
        ppolicy_header_point_2_description_p_13: '2.13. Cross-border transfer of personal data - transfer of personal data to the territory of a foreign state to an authority of a foreign state, a foreign individual or a foreign legal entity.',
        ppolicy_header_point_2_description_p_14: '2.14. Destruction of personal data - any actions as a result of which personal data is destroyed irrevocably with the impossibility of further restoration of the content of personal data in the personal data information system and/or the material media of personal data are destroyed.',

        ppolicy_header_point_3: '3. Basic rights and obligations of the Operator',
        ppolicy_header_point_3_description_p_1: '3.1. The operator has the right:',
        ppolicy_header_point_3_description_p_1_li_1: '— receive from the subject of personal data reliable information and/or documents containing personal data;',
        ppolicy_header_point_3_description_p_1_li_2: '— in the event that the subject of personal data withdraws consent to the processing of personal data, as well as sends an application to stop processing personal data, the Operator has the right to continue processing personal data without the consent of the subject of personal data if there are grounds specified in the Law on Personal Data ;',
        ppolicy_header_point_3_description_p_1_li_3: '— independently determine the composition and list of measures necessary and sufficient to ensure the fulfillment of the obligations provided for by the Law on Personal Data and regulations adopted in accordance with it, unless otherwise provided by the Law on Personal Data or other federal laws.',


        ppolicy_header_point_3_description_p_2: '3.2. The operator is obliged:',
        ppolicy_header_point_3_description_p_2_li_1: '— provide the subject of personal data, at his request, with information regarding the processing of his personal data;',
        ppolicy_header_point_3_description_p_2_li_2: '— organize the processing of personal data in the manner established by the current legislation of the Russian Federation;',
        ppolicy_header_point_3_description_p_2_li_3: '— respond to requests and requests from personal data subjects and their legal representatives in accordance with the requirements of the Personal Data Law;',
        ppolicy_header_point_3_description_p_2_li_4: '— report to the authorized body for the protection of the rights of personal data subjects, at the request of this body, the necessary information within 10 days from the date of receipt of such a request;\n',
        ppolicy_header_point_3_description_p_2_li_5: '— publish or otherwise provide unrestricted access to this Policy regarding the processing of personal data;',
        ppolicy_header_point_3_description_p_2_li_6: '— take legal, organizational and technical measures to protect personal data from unauthorized or accidental access, destruction, modification, blocking, copying, provision, distribution of personal data, as well as from other unlawful actions in relation to personal data;',
        ppolicy_header_point_3_description_p_2_li_7: '— stop the transfer (distribution, provision, access) of personal data, stop processing and destroy personal data in the manner and cases provided for by the Law on Personal Data;',
        ppolicy_header_point_3_description_p_2_li_8: '— fulfill other duties provided for by the Personal Data Law.',

        ppolicy_header_point_4: '4. Basic rights and obligations of personal data subjects',
        ppolicy_header_point_4_description_p_1: '4.1. Subjects of personal data have the right:',
        ppolicy_header_point_4_description_p_1_li_1: '—to receive information regarding the processing of his personal data, except in cases provided for by federal laws. The information is provided to the subject of personal data by the Operator in an accessible form, and it should not contain personal data relating to other subjects of personal data, except in cases where there are legal grounds for the disclosure of such personal data. The list of information and the procedure for obtaining it is established by the Law on Personal Data;',
        ppolicy_header_point_4_description_p_1_li_2: '— demand from the operator clarification of his personal data, blocking or destruction of it if the personal data is incomplete, outdated, inaccurate, illegally obtained or is not necessary for the stated purpose of processing, as well as take measures provided by law to protect their rights ;',
        ppolicy_header_point_4_description_p_1_li_3: '— put forward a condition of prior consent when processing personal data in order to promote goods, works and services on the market;',
        ppolicy_header_point_4_description_p_1_li_4: '—to withdraw consent to the processing of personal data, as well as to send a request to stop processing personal data;',
        ppolicy_header_point_4_description_p_1_li_5: '— to appeal to the authorized body for the protection of the rights of personal data subjects or in court against unlawful actions or inaction of the Operator when processing his personal data;',
        ppolicy_header_point_4_description_p_1_li_6: '— to exercise other rights provided for by the legislation of the Russian Federation.',

        ppolicy_header_point_4_description_p_2: '4.2. Subjectyou are obliged to provide personal data:',
        ppolicy_header_point_4_description_p_2_li_1: '— provide the Operator with reliable information about yourself;',
        ppolicy_header_point_4_description_p_2_li_2: '— inform the Operator about clarification (updating, changing) of your personal data.',

        ppolicy_header_point_4_description_p_3: '4.3. Persons who provided the Operator with false information about themselves or information about another subject of personal data without the latter’s consent are liable in accordance with the legislation of the Russian Federation.',

        ppolicy_header_point_5: '5. Principles for processing personal data',
        ppolicy_header_point_5_description_p_1: '5.1. The processing of personal data is carried out on a legal and fair basis.\n',
        ppolicy_header_point_5_description_p_2: '5.2. The processing of personal data is limited to the achievement of specific, pre-defined and legitimate purposes. Processing of personal data that is incompatible with the purposes of collecting personal data is not permitted.',
        ppolicy_header_point_5_description_p_3: '5.3. It is not allowed to combine databases containing personal data, the processing of which is carried out for purposes that are incompatible with each other.',
        ppolicy_header_point_5_description_p_4: '5.4. Only personal data that meets the purposes of their processing are subject to processing.',
        ppolicy_header_point_5_description_p_5: '5.5. The content and volume of personal data processed correspond to the stated purposes of processing. Redundancy of the processed personal data in relation to the stated purposes of their processing is not allowed.',
        ppolicy_header_point_5_description_p_6: '5.6. When processing personal data, the accuracy of personal data, their sufficiency, and, where necessary, relevance in relation to the purposes of processing personal data are ensured. The operator takes the necessary measures and/or ensures that they are taken to delete or clarify incomplete or inaccurate data.',
        ppolicy_header_point_5_description_p_7: '5.7. The storage of personal data is carried out in a form that makes it possible to identify the subject of personal data, no longer than required by the purposes of processing personal data, unless the period for storing personal data is established by federal law, an agreement to which the subject of personal data is a party, beneficiary or guarantor. The processed personal data is destroyed or anonymized upon achievement of the processing goals or in the event of the loss of the need to achieve these goals, unless otherwise provided by federal law.',

        ppolicy_header_point_6: 'Purposes of processing personal data',
        ppolicy_table_point_6_col_1_row1: 'Purpose of processing',
        ppolicy_table_point_6_col_1_row2: 'Personal data',
        ppolicy_table_point_6_col_1_row3: 'Legal basis',
        ppolicy_table_point_6_col_1_row4: 'Types of personal data processing',
        ppolicy_table_point_6_col_2_row1: 'providing the User with access to services, information and/or materials contained on the website',
        ppolicy_table_point_6_col_2_row2: 'email address',
        ppolicy_table_point_6_col_2_row3: 'Federal Law "On Information, Information Technologies and Information Protection" dated July 27, 2006 N 149-FZ',
        ppolicy_table_point_6_col_2_row4_li_1: 'Collection, recording, systematization, accumulation, storage, destruction and depersonalization of personal data',
        ppolicy_table_point_6_col_2_row4_li_2: 'Sending newsletters to an email address',


        ppolicy_header_point_7: '7. Conditions for processing personal data',
        ppolicy_header_point_7_description_p_1: '7.1. Processing of personal data is carried out with the consent of the subject of personal data to the processing of his personal data.',
        ppolicy_header_point_7_description_p_2: '7.2. Processing of personal data is necessary to achieve the goals provided for by an international treaty of the Russian Federation or law, to implement the functions, powers and responsibilities assigned by the legislation of the Russian Federation to the operator.',
        ppolicy_header_point_7_description_p_3: '7.3. The processing of personal data is necessary for the administration of justice, the execution of a judicial act, an act of another body or official, subject to execution in accordance with the legislation of the Russian Federation on enforcement proceedings.',
        ppolicy_header_point_7_description_p_4: '7.4. Processing of personal data is necessary for the execution of an agreement to which the subject of personal data is a party or beneficiary or guarantor, as well as for concluding an agreement at the initiative of the subject of personal data or an agreement under which the subject of personal data will be a beneficiary or guarantor.',
        ppolicy_header_point_7_description_p_5: '7.5. The processing of personal data is necessary to exercise the rights and legitimate interests of the operator or third parties or to achieve socially significant goals.provided that the rights and freedoms of the personal data subject are not violated.',
        ppolicy_header_point_7_description_p_6: '7.6. The processing of personal data is carried out, access to an unlimited number of persons is provided by the subject of personal data or at his request (hereinafter referred to as publicly available personal data).',
        ppolicy_header_point_7_description_p_7: '7.7. We are processing personal data that is subject to publication or mandatory disclosure in accordance with federal law.',

        ppolicy_header_point_8: '8. The procedure for collecting, storing, transferring and other types of processing of personal data',
        ppolicy_header_point_8_text: 'The security of personal data processed by the Operator is ensured by implementing legal, organizational and technical measures necessary to fully comply with the requirements of current legislation in the field of personal data protection.',
        ppolicy_header_point_8_description_p_1: '8.1. The operator ensures the safety of personal data and takes all possible measures to prevent access to personal data by unauthorized persons.',
        ppolicy_header_point_8_description_p_2: '8.2. The User`s personal data will never, under any circumstances, be transferred to third parties, except in cases related to the implementation of current legislation or in the event that the subject of the personal data has given consent to the Operator to transfer data to a third party to fulfill obligations under a civil law contract.',
        ppolicy_header_point_8_description_p_3_part_1: '8.3. If inaccuracies in personal data are identified, the User can update them independently by sending a notification to the Operator to the Operator`s email address',
        ppolicy_header_point_8_description_p_3_part_2: 'marked "Updating personal data.',
        ppolicy_header_point_8_description_p_4_part_1: '8.4. The period for processing personal data is determined by the achievement of the purposes for which the personal data were collected, unless a different period is provided for by the contract or current legislation.',
        ppolicy_header_point_8_description_p_4_part_2: 'The User may at any time withdraw his consent to the processing of personal data by sending a notification to the Operator via email to the Operator`s email address',
        ppolicy_header_point_8_description_p_4_part_3: 'marked “Withdrawal of consent to the processing of personal data.',
        ppolicy_header_point_8_description_p_5: '8.5. All information that is collected by third-party services, including payment systems, communications and other service providers, is stored and processed by these persons (Operators) in accordance with their User Agreement and Privacy Policy. Subject of personal data and/or with specified documents. The operator is not responsible for the actions of third parties, including the service providers specified in this paragraph.',
        ppolicy_header_point_8_description_p_6: '8.6. Prohibitions established by the subject of personal data on the transfer (except for providing access), as well as on processing or conditions for processing (except for gaining access) of personal data permitted for distribution, do not apply in cases of processing personal data in state, public and other public interests determined by law RF.',
        ppolicy_header_point_8_description_p_7: '8.7. When processing personal data, the operator ensures the confidentiality of personal data.',
        ppolicy_header_point_8_description_p_8: '8.8. The operator stores personal data in a form that makes it possible to identify the subject of personal data for no longer than required by the purposes of processing personal data, unless the period for storing personal data is established by federal law, an agreement to which the subject of personal data is a party, beneficiary or guarantor. ',
        ppolicy_header_point_8_description_p_9: '8.9. The condition for terminating the processing of personal data may be the achievement of the purposes of processing personal data, the expiration of the consent of the subject of personal data, withdrawal of consent by the subject of personal data or a requirement to stop processing personal data, as well as identification of unlawful processing of personal data.\n',

        ppolicy_header_point_9: '9. List of actions performed by the Operator with the received personal data',
        ppolicy_header_point_9_description_p_1: '9.1. The operator collects, records, systematizes, accumulates, stores, refines (updates, changes), extracts, uses, transfers (distribution, provision, access), depersonalizes, blocks, deletes and destroys personal data.',
        ppolicy_header_point_9_description_p_2: '9.2. The operator carries out automated processing of personal data with the receipt and/or transfer of received informationabout information and telecommunication networks or without it.',

        ppolicy_header_point_10: '10. Cross-border transfer of personal data',
        ppolicy_header_point_10_description_p_1: '10.1. Before starting activities for the cross-border transfer of personal data, the operator is obliged to notify the authorized body for the protection of the rights of personal data subjects of its intention to carry out cross-border transfer of personal data (such notification is sent separately from the notification of the intention to process personal data).',
        ppolicy_header_point_10_description_p_2: '10.2. Before submitting the above notification, the operator is obliged to obtain relevant information from the authorities of a foreign state, foreign individuals, foreign legal entities to whom the cross-border transfer of personal data is planned.',

        ppolicy_header_point_11: '11. Confidentiality of personal data',
        ppolicy_header_point_11_description_p_1: 'The operator and other persons who have access to personal data are obliged not to disclose to third parties or distribute personal data without the consent of the subject of personal data, unless otherwise provided by federal law.',

        ppolicy_header_point_12: '12. Final provisions',
        ppolicy_header_point_12_description_p_1: '12.1. The User can receive any clarification on issues of interest regarding the processing of his personal data by contacting the Operator via email',
        ppolicy_header_point_12_description_p_2: '12.2. This document will reflect any changes to the Operator’s personal data processing policy. The policy is valid indefinitely until it is replaced by a new version.',
        ppolicy_header_point_12_description_p_3: '12.3. The current version of the Policy is freely available on the Internet at ',
    },
    ru: {
        ppolicy_main_header: 'Политика в отношении обработки персональных данных',

        ppolicy_header_point_1: '1. Общие положения',
        ppolicy_header_point_1_description_part_1: 'Настоящая политика обработки персональных данных составлена в соответствии с требованиями Федерального закона от 27.07.2006. №152-ФЗ «Оперсональных данных» (далее— Закон о персональных данных) и определяет порядок обработки персональных данных и меры по обеспечению безопасности персональных данных, предпринимаемые',
        ppolicy_header_point_1_description_part_2: '(далее - Оператор)',
        ppolicy_header_point_1_description_p_1: '1.1. Оператор ставит своей важнейшей целью и условием осуществления своей деятельности соблюдение прав и свобод человека и гражданина при обработке его персональных данных, в том числе защиты прав на неприкосновенность частной жизни, личную и семейную тайну.',
        ppolicy_header_point_1_description_p_2: '1.2. Настоящая политика Оператора в отношении обработки персональных данных (далее — Политика) применяется ко всей информации, которую Оператор может получить о посетителях веб-сайта',

        ppolicy_header_point_2: '2. Основные понятия, используемые в Политике',
        ppolicy_header_point_2_description_p_1: '2.1. Автоматизированная обработка персональных данных — обработка персональных данных с помощью средств вычислительной техники.',
        ppolicy_header_point_2_description_p_2: '2.2. Блокирование персональных данных — временное прекращение обработки персональных данных (за исключением случаев, если обработка необходима для уточнения персональных данных).',
        ppolicy_header_point_2_description_p_3: '2.3. Веб-сайт — совокупность графических и информационных материалов, а также программ для ЭВМ и баз данных, обеспечивающих их доступность в сети интернет по сетевому адресу',
        ppolicy_header_point_2_description_p_4: '2.4. Информационная система персональных данных — совокупность содержащихся в базах данных персональных данных и обеспечивающих их обработку информационных технологий и технических средств.',
        ppolicy_header_point_2_description_p_5: '2.5. Обезличивание персональных данных — действия, в результате которых невозможно определить без использования дополнительной информации принадлежность персональных данных конкретному Пользователю или иному субъекту персональных данных.',
        ppolicy_header_point_2_description_p_6: '2.6. Обработка персональных данных — любое действие (операция) или совокупность действий (операций), совершаемых с использованием средств автоматизации или без использования таких средств с персональными данными, включая сбор, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передачу (распространение, предоставление, доступ), обезличивание, блокирование, удаление, уничтожение персональных данных.',
        ppolicy_header_point_2_description_p_7: '2.7. Оператор — государственный орган, муниципальный орган, юридическое или физическое лицо, самостоятельно или совместно с другими лицами организующие и/или осуществляющие обработку персональных данных, а также определяющие цели обработки персональных данных, состав персональных данных, подлежащих обработке, действия (операции), совершаемые с персональными данными.',
        ppolicy_header_point_2_description_p_8: '2.8. Персональные данные — любая информация, относящаяся прямо или косвенно к определенному или определяемому Пользователю веб-сайта',
        ppolicy_header_point_2_description_p_9: '2.9. Персональные данные, разрешенные субъектом персональных данных для распространения, — персональные данные, доступ неограниченного круга лиц к которым предоставлен субъектом персональных данных путем дачи согласия на обработку персональных данных, разрешенных субъектом персональных данных для распространения в порядке, предусмотренном Законом о персональных данных (далее — персональные данные, разрешенные для распространения).',
        ppolicy_header_point_2_description_p_10: '2.10. Пользователь — любой посетитель веб-сайта',
        ppolicy_header_point_2_description_p_11: '2.11. Предоставление персональных данных — действия, направленные на раскрытие персональных данных определенному лицу или определенному кругу лиц.',
        ppolicy_header_point_2_description_p_12: '2.12. Распространение персональных данных — любые действия, направленные на раскрытие персональных данных неопределенному кругу лиц (передача персональных данных) или на ознакомление с персональными данными неограниченного круга лиц, в том числе обнародование персональных данных в средствах массовой информации, размещение в информационно-телекоммуникационных сетях или предоставление доступа к персональным данным каким-либо иным способом.',
        ppolicy_header_point_2_description_p_13: '2.13. Трансграничная передача персональных данных — передача персональных данных на территорию иностранного государства органу власти иностранного государства, иностранному физическому или иностранному юридическому лицу.',
        ppolicy_header_point_2_description_p_14: '2.14. Уничтожение персональных данных — любые действия, в результате которых персональные данные уничтожаются безвозвратно с невозможностью дальнейшего восстановления содержания персональных данных в информационной системе персональных данных и/или уничтожаются материальные носители персональных данных.',

        ppolicy_header_point_3: '3. Основные права и обязанности Оператора',
        ppolicy_header_point_3_description_p_1: '3.1. Оператор имеет право:',
        ppolicy_header_point_3_description_p_1_li_1: '— получать от субъекта персональных данных достоверные информацию и/или документы, содержащие персональные данные;',
        ppolicy_header_point_3_description_p_1_li_2: '— в случае отзыва субъектом персональных данных согласия на обработку персональных данных, а также, направления обращения с требованием о прекращении обработки персональных данных, Оператор вправе продолжить обработку персональных данных без согласия субъекта персональных данных при наличии оснований, указанных в Законе о персональных данных;',
        ppolicy_header_point_3_description_p_1_li_3: '— самостоятельно определять состав и перечень мер, необходимых и достаточных для обеспечения выполнения обязанностей, предусмотренных Законом о персональных данных и принятыми в соответствии с ним нормативными правовыми актами, если иное не предусмотрено Законом о персональных данных или другими федеральными законами.',

        ppolicy_header_point_3_description_p_2: '3.2. Оператор обязан:',
        ppolicy_header_point_3_description_p_2_li_1: '— предоставлять субъекту персональных данных по его просьбе информацию, касающуюся обработки его персональных данных;',
        ppolicy_header_point_3_description_p_2_li_2: '— организовывать обработку персональных данных в порядке, установленном действующим законодательством РФ;',
        ppolicy_header_point_3_description_p_2_li_3: '— отвечать на обращения и запросы субъектов персональных данных и их законных представителей в соответствии с требованиями Закона о персональных данных;',
        ppolicy_header_point_3_description_p_2_li_4: '— сообщать в уполномоченный орган по защите прав субъектов персональных данных по запросу этого органа необходимую информацию в течение 10 дней с даты получения такого запроса;\n',
        ppolicy_header_point_3_description_p_2_li_5: '— публиковать или иным образом обеспечивать неограниченный доступ к настоящей Политике в отношении обработки персональных данных;',
        ppolicy_header_point_3_description_p_2_li_6: '— принимать правовые, организационные и технические меры для защиты персональных данных от неправомерного или случайного доступа к ним, уничтожения, изменения, блокирования, копирования, предоставления, распространения персональных данных, а также от иных неправомерных действий в отношении персональных данных;',
        ppolicy_header_point_3_description_p_2_li_7: '— прекратить передачу (распространение, предоставление, доступ) персональных данных, прекратить обработку и уничтожить персональные данные в порядке и случаях, предусмотренных Законом о персональных данных;',
        ppolicy_header_point_3_description_p_2_li_8: '— исполнять иные обязанности, предусмотренные Законом о персональных данных.',


        ppolicy_header_point_4: '4. Основные права и обязанности субъектов персональных данных',
        ppolicy_header_point_4_description_p_1: '4.1. Субъекты персональных данных имеют право:',
        ppolicy_header_point_4_description_p_1_li_1: '— получать информацию, касающуюся обработки его персональных данных, за исключением случаев, предусмотренных федеральными законами. Сведения предоставляются субъекту персональных данных Оператором в доступной форме, и в них не должны содержаться персональные данные, относящиеся к другим субъектам персональных данных, за исключением случаев, когда имеются законные основания для раскрытия таких персональных данных. Перечень информации и порядок ее получения установлен Законом о персональных данных;',
        ppolicy_header_point_4_description_p_1_li_2: '— требовать от оператора уточнения его персональных данных, их блокирования или уничтожения в случае, если персональные данные являются неполными, устаревшими, неточными, незаконно полученными или не являются необходимыми для заявленной цели обработки, а также принимать предусмотренные законом меры по защите своих прав;',
        ppolicy_header_point_4_description_p_1_li_3: '— выдвигать условие предварительного согласия при обработке персональных данных в целях продвижения на рынке товаров, работ и услуг;',
        ppolicy_header_point_4_description_p_1_li_4: '— на отзыв согласия на обработку персональных данных, а также, на направление требования о прекращении обработки персональных данных;',
        ppolicy_header_point_4_description_p_1_li_5: '— обжаловать в уполномоченный орган по защите прав субъектов персональных данных или в судебном порядке неправомерные действия или бездействие Оператора при обработке его персональных данных;',
        ppolicy_header_point_4_description_p_1_li_6: '— на осуществление иных прав, предусмотренных законодательством РФ.',

        ppolicy_header_point_4_description_p_2:  '4.2. Субъекты персональных данных обязаны:',
        ppolicy_header_point_4_description_p_2_li_1: '— предоставлять Оператору достоверные данные о себе;',
        ppolicy_header_point_4_description_p_2_li_2: '— сообщать Оператору об уточнении (обновлении, изменении) своих персональных данных.',

        ppolicy_header_point_4_description_p_3: '4.3. Лица, передавшие Оператору недостоверные сведения о себе, либо сведения о другом субъекте персональных данных без согласия последнего, несут ответственность в соответствии с законодательством РФ.',

        ppolicy_header_point_5: '5. Принципы обработки персональных данных',
        ppolicy_header_point_5_description_p_1: '5.1. Обработка персональных данных осуществляется на законной и справедливой основе.\n',
        ppolicy_header_point_5_description_p_2: '5.2. Обработка персональных данных ограничивается достижением конкретных, заранее определенных и законных целей. Не допускается обработка персональных данных, несовместимая с целями сбора персональных данных.',
        ppolicy_header_point_5_description_p_3: '5.3. Не допускается объединение баз данных, содержащих персональные данные, обработка которых осуществляется в целях, несовместимых между собой.',
        ppolicy_header_point_5_description_p_4: '5.4. Обработке подлежат только персональные данные, которые отвечают целям их обработки.',
        ppolicy_header_point_5_description_p_5: '5.5. Содержание и объем обрабатываемых персональных данных соответствуют заявленным целям обработки. Не допускается избыточность обрабатываемых персональных данных по отношению к заявленным целям их обработки.',
        ppolicy_header_point_5_description_p_6: '5.6. При обработке персональных данных обеспечивается точность персональных данных, их достаточность, а в необходимых случаях и актуальность по отношению к целям обработки персональных данных. Оператор принимает необходимые меры и/или обеспечивает их принятие по удалению или уточнению неполных или неточных данных.',
        ppolicy_header_point_5_description_p_7: '5.7. Хранение персональных данных осуществляется в форме, позволяющей определить субъекта персональных данных, не дольше, чем этого требуют цели обработки персональных данных, если срок хранения персональных данных не установлен федеральным законом, договором, стороной которого, выгодоприобретателем или поручителем по которому является субъект персональных данных. Обрабатываемые персональные данные уничтожаются либо обезличиваются по достижении целей обработки или в случае утраты необходимости в достижении этих целей, если иное не предусмотрено федеральным законом.',

        ppolicy_header_point_6: 'Цели обработки персональных данных',
        ppolicy_table_point_6_col_1_row1:  'Цель обработки',
        ppolicy_table_point_6_col_1_row2: 'Персональные данные',
        ppolicy_table_point_6_col_1_row3: 'Правовые основания',
        ppolicy_table_point_6_col_1_row4: 'Виды обработки персональных данных',
        ppolicy_table_point_6_col_2_row1: 'предоставление доступа Пользователю к сервисам, информации и/или материалам, содержащимся на веб-сайте',
        ppolicy_table_point_6_col_2_row2: 'электронный адрес',
        ppolicy_table_point_6_col_2_row3: 'Федеральный закон «Об информации, информационных технологиях и о защите информации» от 27.07.2006 N 149-ФЗ',
        ppolicy_table_point_6_col_2_row4_li_1: 'Сбор, запись, систематизация, накопление, хранение, уничтожение и обезличивание персональных данных',
        ppolicy_table_point_6_col_2_row4_li_2: 'Отправка информационных писем на адрес электронной почты',


        ppolicy_header_point_7: '7. Условия обработки персональных данных',
        ppolicy_header_point_7_description_p_1: '7.1. Обработка персональных данных осуществляется с согласия субъекта персональных данных на обработку его персональных данных.',
        ppolicy_header_point_7_description_p_2: '7.2. Обработка персональных данных необходима для достижения целей, предусмотренных международным договором Российской Федерации или законом, для осуществления возложенных законодательством Российской Федерации на оператора функций, полномочий и обязанностей.',
        ppolicy_header_point_7_description_p_3: '7.3. Обработка персональных данных необходима для осуществления правосудия, исполнения судебного акта, акта другого органа или должностного лица, подлежащих исполнению в соответствии с законодательством Российской Федерации об исполнительном производстве.',
        ppolicy_header_point_7_description_p_4: '7.4. Обработка персональных данных необходима для исполнения договора, стороной которого либо выгодоприобретателем или поручителем по которому является субъект персональных данных, а также для заключения договора по инициативе субъекта персональных данных или договора, по которому субъект персональных данных будет являться выгодоприобретателем или поручителем.',
        ppolicy_header_point_7_description_p_5: '7.5. Обработка персональных данных необходима для осуществления прав и законных интересов оператора или третьих лиц либо для достижения общественно значимых целей при условии, что при этом не нарушаются права и свободы субъекта персональных данных.',
        ppolicy_header_point_7_description_p_6: '7.6. Осуществляется обработка персональных данных, доступ неограниченного круга лиц к которым предоставлен субъектом персональных данных либо по его просьбе (далее — общедоступные персональные данные).',
        ppolicy_header_point_7_description_p_7: '7.7. Осуществляется обработка персональных данных, подлежащих опубликованию или обязательному раскрытию в соответствии с федеральным законом.',

        ppolicy_header_point_8: '8. Порядок сбора, хранения, передачи и других видов обработки персональных данных',
        ppolicy_header_point_8_text: 'Безопасность персональных данных, которые обрабатываются Оператором, обеспечивается путем реализации правовых, организационных и технических мер, необходимых для выполнения в полном объеме требований действующего законодательства в области защиты персональных данных.',
        ppolicy_header_point_8_description_p_1: '8.1. Оператор обеспечивает сохранность персональных данных и принимает все возможные меры, исключающие доступ к персональным данным неуполномоченных лиц.',
        ppolicy_header_point_8_description_p_2: '8.2. Персональные данные Пользователя никогда, ни при каких условиях не будут переданы третьим лицам, за исключением случаев, связанных с исполнением действующего законодательства либо в случае, если субъектом персональных данных дано согласие Оператору на передачу данных третьему лицу для исполнения обязательств по гражданско-правовому договору.',
        ppolicy_header_point_8_description_p_3_part_1: '8.3. В случае выявления неточностей в персональных данных, Пользователь может актуализировать их самостоятельно, путем направления Оператору уведомление на адрес электронной почты Оператора',
        ppolicy_header_point_8_description_p_3_part_2: 'с пометкой «Актуализация персональных данных».',
        ppolicy_header_point_8_description_p_4_part_1: '8.4. Срок обработки персональных данных определяется достижением целей, для которых были собраны персональные данные, если иной срок не предусмотрен договором или действующим законодательством.',
        ppolicy_header_point_8_description_p_4_part_2: 'Пользователь может в любой момент отозвать свое согласие на обработку персональных данных, направив Оператору уведомление посредством электронной почты на электронный адрес Оператора',
        ppolicy_header_point_8_description_p_4_part_3: 'с пометкой «Отзыв согласия на обработку персональных данных».',
        ppolicy_header_point_8_description_p_5: '8.5. Вся информация, которая собирается сторонними сервисами, в том числе платежными системами, средствами связи и другими поставщиками услуг, хранится и обрабатывается указанными лицами (Операторами) в соответствии с их Пользовательским соглашением и Политикой конфиденциальности. Субъект персональных данных и/или с указанными документами. Оператор не несет ответственность за действия третьих лиц, в том числе указанных в настоящем пункте поставщиков услуг.',
        ppolicy_header_point_8_description_p_6: '8.6. Установленные субъектом персональных данных запреты на передачу (кроме предоставления доступа), а также на обработку или условия обработки (кроме получения доступа) персональных данных, разрешенных для распространения, не действуют в случаях обработки персональных данных в государственных, общественных и иных публичных интересах, определенных законодательством РФ.',
        ppolicy_header_point_8_description_p_7: '8.7. Оператор при обработке персональных данных обеспечивает конфиденциальность персональных данных.',
        ppolicy_header_point_8_description_p_8: '8.8. Оператор осуществляет хранение персональных данных в форме, позволяющей определить субъекта персональных данных, не дольше, чем этого требуют цели обработки персональных данных, если срок хранения персональных данных не установлен федеральным законом, договором, стороной которого, выгодоприобретателем или поручителем по которому является субъект персональных данных.',
        ppolicy_header_point_8_description_p_9: '8.9. Условием прекращения обработки персональных данных может являться достижение целей обработки персональных данных, истечение срока действия согласия субъекта персональных данных, отзыв согласия субъектом персональных данных или требование о прекращении обработки персональных данных, а также выявление неправомерной обработки персональных данных.\n',

        ppolicy_header_point_9: '9. Перечень действий, производимых Оператором с полученными персональными данными',
        ppolicy_header_point_9_description_p_1: '9.1. Оператор осуществляет сбор, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передачу (распространение, предоставление, доступ), обезличивание, блокирование, удаление и уничтожение персональных данных.',
        ppolicy_header_point_9_description_p_2: '9.2. Оператор осуществляет автоматизированную обработку персональных данных с получением и/или передачей полученной информации по информационно-телекоммуникационным сетям или без таковой.',

        ppolicy_header_point_10: '10. Трансграничная передача персональных данных',
        ppolicy_header_point_10_description_p_1: '10.1. Оператор до начала осуществления деятельности по трансграничной передаче персональных данных обязан уведомить уполномоченный орган по защите прав субъектов персональных данных о своем намерении осуществлять трансграничную передачу персональных данных (такое уведомление направляется отдельно от уведомления о намерении осуществлять обработку персональных данных).',
        ppolicy_header_point_10_description_p_2: '10.2. Оператор до подачи вышеуказанного уведомления, обязан получить от органов власти иностранного государства, иностранных физических лиц, иностранных юридических лиц, которым планируется трансграничная передача персональных данных, соответствующие сведения.',

        ppolicy_header_point_11: '11. Конфиденциальность персональных данных',
        ppolicy_header_point_11_description_p_1: 'Оператор и иные лица, получившие доступ к персональным данным, обязаны не раскрывать третьим лицам и не распространять персональные данные без согласия субъекта персональных данных, если иное не предусмотрено федеральным законом.',

        ppolicy_header_point_12: '12. Заключительные положения',
        ppolicy_header_point_12_description_p_1: '12.1. Пользователь может получить любые разъяснения по интересующим вопросам, касающимся обработки его персональных данных, обратившись к Оператору с помощью электронной почты',
        ppolicy_header_point_12_description_p_2: '12.2. В данном документе будут отражены любые изменения политики обработки персональных данных Оператором. Политика действует бессрочно до замены ее новой версией.',
        ppolicy_header_point_12_description_p_3: '12.3. Актуальная версия Политики в свободном доступе расположена в сети Интернет по адресу',
    }
}