export const main_form_work = {
    en: {
        form_h1_1_span: 'Let`s',
        form_h1_1: 'get to know each other',
        name: 'Name',
        email: 'E-mail',
        phone: 'Phone',
        send_button: 'Send',
        form_h1_2_span: 'Also',
        form_h1_2: 'you can contact us',
        form_success_h1_span: 'Great!',
        form_success_h1: 'We will contact you soon',
        form_error_h1_span: 'Try again later',
        form_error_h1: 'Something went wrong',
        form_close: 'Close',
    },
    ru: {
        form_h1_1_span: 'Давайте',
        form_h1_1: 'знакомиться',
        name: 'Имя',
        email: 'Почта',
        phone: 'Телефон',
        send_button: 'Отправить',
        form_h1_2_span: 'Также',
        form_h1_2: 'вы можете написать нам самостоятельно',
        form_success_h1_span: 'Отлично!',
        form_success_h1: 'Мы скоро свяжемся с вами',
        form_error_h1_span: 'Попробуйте снова позже',
        form_error_h1: 'Что-то пошло не так',
        form_close: 'Закрыть',
    }
}