export const main_services = {
    en: {
        services_text_span: 'Our team',
        services_text: ' provides any web services, from the backend and design of everything, right up to brand identity, ending with technical support for your products',
        services_bots_tg: 'Telegram',
        services_bots_and: 'and',
        services_bots_discord: 'Discord',
        services_bots: 'bots for mailing, parsing and webapps',
        services_stores: 'Online stores',
        services_ai: 'Implementing ai into your projects',
        services_design: 'Design and identity creation',
        services_landing: 'Landing pages',
        services_managing: 'Support and maintenance of IT projects',
        services_scale: 'Full scale web projects',
    },
    ru: {
        services_text_span: 'Наша команда ',
        services_text: 'предоставляет любые веб услуги, начиная от разработки серверной части и дизайна, вплоть до фирменного стиля, заканчивая технической поддержкой ваших продуктов',
        services_bots_tg: "Telegram",
        services_bots_and: "и",
        services_bots_discord: "Discord",
        services_bots: "webapps и боты для почтовой рассылки, парсинга",
        services_stores: "Интернет-магазины",
        services_ai: "Внедрение искусственного интеллекта в ваши проекты",
        services_design: "Дизайн и создание айдентики",
        services_landing: 'Посадочные страницы',
        services_managing: 'Поддержка и сопровождение IT проектов',
        services_scale: 'Создание проектов любой сложности под ключ',
    },
}
