export const main_contacts = {
    en: {
        contacts_contacts: 'Contacts',
        contacts_telegram_bot: 'Telegram bot',
        contacts_telegram: 'Telegram',
    },
    ru: {
        contacts_contacts: 'Контакты',
        contacts_telegram_bot: 'Telegram бот',
        contacts_telegram: 'Telegram',
    }
}