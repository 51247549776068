import {createRouter, createWebHashHistory} from 'vue-router'

const routes = [
    {
        path: '/',
        name: 'home',
        component: () => import('../views/HomeView'),
    },
    {
        path: '/privacypolicy',
        name: 'ppolicy',
        component: () => import('../views/PpolicyView'),
    },
]

const router = createRouter({history: createWebHashHistory(), routes})

// Add a global beforeEach guard
router.beforeEach((to, from, next) => {
  // Scroll to the top of the page before each route is mounted
  window.scrollTo(0, 0);

  // Ensure the rest of the navigation guard process continues
  next();
});

export default router