export const main_offer = {
    en: {
        offer_text_span: "Our projects ",
        offer_text: "- are exemplars of future solutions. They open up opportunities for rethinking, refining the present, creating and developing.",
        offer_button: "Order a project",
        c1slogan: "Creating the future",
        c1h1span: "Innovative ",
        c1h1: "Team",
        c1description: "Tess.web offers a full cycle of work - from analysis and design to development and support. We also provide services for creating logos, banners, animations, and other design elements to make your website even more attractive.",
        c2description: "Our web development and graphic design skills will help your business increase sales through your website, attract new customers, and improve its functional and visual components.",
        c3description: "We will be happy to help you bring your ideas to life and achieve new goals.",
        c4description: "We work with clients from all over the world and accept any payment methods."
    },
    ru: {
        offer_text_span: 'Наши проекты ',
        offer_text: '- это образцы решений будущего. Они открывают возможность для переосмысления, проработки настоящего, созидания и развития.',
        offer_button: 'Заказать проект',
        c1slogan: 'Создаем будущее',
        c1h1span: 'Инновационная ',
        c1h1: 'Команда',
        c1description: 'Tess.web предлагает полный цикл работы - от анализа и дизайна до разработки и поддержки. Мы также предоставляем услуги по созданию логотипов, баннеров, анимаций и других элементов дизайна, чтобы сделать ваш сайт еще более привлекательным.',
        c2description: 'Наши навыки разработки веб-сайтов и графического дизайна помогут вашему бизнесу увеличить продажи через свой веб-сайт, привлечь новых клиентов и улучшить его функциональную и визуальную составляющие.',
        c3description: 'Мы будем рады помочь вам воплотить ваши идеи в жизнь и достичь новых целей.',
        c4description: 'Мы работаем оффициально с клиентами со всего мира и принимаем любые способы оплаты',
    },
}
