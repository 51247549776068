import { createApp } from 'vue';
import { createI18n } from 'vue-i18n';

import App from './App.vue'
import router from './router'
import store from "./store";
import 'bootstrap/dist/css/bootstrap.min.css';
import VueAnimXyz from '@animxyz/vue3';
import '@animxyz/core';
import "@/assets/css/style.less";
import messages from './translation/messages';

const i18n = createI18n({
    locale: 'en',
    fallbackLocale: 'en',
    messages,
  });

createApp(App)
    .use(router)
    .use(store)
    .use(i18n)
    .use(VueAnimXyz)
    .mount('#app')